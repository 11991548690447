import React from 'react'

import * as S from './Footer.styles'

const defaultProps = {}
const propTypes = {}

const blankProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

const FooterItems = [
  {
    label: 'Company',
    items: [
      { label: 'About Us', href: '/about-us' },
      { label: 'Terms of Service', href: '/terms-of-service' },
      { label: 'Privacy Policy', href: '/privacy-policy' },
      { label: 'Contact Us', href: '/contact-us' },
    ],
  },
  {
    label: 'Publish on TrialSite',
    items: [
      { label: 'Writing Best Practices', href: '/writing-best-practices' },
      { label: 'Why TrialSite?', href: '/publish-on-trialsite' },
    ],
  },
  {
    label: 'Follow Us',
    items: [
      {
        label: (
          <S.SocialLabel>
            <S.YoutubeIcon />
            &nbsp;YouTube
          </S.SocialLabel>
        ),
        href: 'https://www.youtube.com/c/TrialSiteNews',
        linkProps: blankProps,
      },
      {
        label: (
          <S.SocialLabel>
            <S.TwitterIcon />
            &nbsp;Twitter
          </S.SocialLabel>
        ),
        href: 'https://twitter.com/TrialsiteN',
        linkProps: blankProps,
      },
      {
        label: (
          <S.SocialLabel>
            <S.FacebookIcon />
            &nbsp;Facebook
          </S.SocialLabel>
        ),
        href: 'https://www.facebook.com/TrialSiteN/',
        linkProps: blankProps,
      },
    ],
  },
]

export const Footer = () => {
  return (
    <S.Container>
      <S.Content>
        <S.FooterItems items={FooterItems} />
        <S.TrialSiteInfo />
      </S.Content>
      <S.Copyright>© 2025 - Trial Site News</S.Copyright>
    </S.Container>
  )
}

Footer.defaultProps = defaultProps
Footer.propTypes = propTypes

export default Footer
